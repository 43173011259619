import { Security } from "Models/models";
import { deleteCookie } from "Functions/functions";
/**
 * @desc List of profiles element with your route
 */
const profilesRoutes = new Map();
profilesRoutes.set("/dashboard/utilidades", "Utilidades");
profilesRoutes.set("/dashboard/utilidades/comercial", "Utilidades");
profilesRoutes.set("/dashboard/utilidades/tragiafas", "Utilidades");
profilesRoutes.set("/dashboard/utilidades/tragcon", "Utilidades");
profilesRoutes.set("/dashboard/utilidades/casinos", "Utilidades");
profilesRoutes.set("/dashboard/utilidades/online", "Utilidades");
profilesRoutes.set("/dashboard/utilidades/comparativos", "Utilidades");
profilesRoutes.set("/dashboard/utilidades/exportar", "Utilidades");
profilesRoutes.set("/dashboard", "Tablero principal");
profilesRoutes.set("/dashboard/infocomercial/densdeagencias", "Dencidad de agencias");
profilesRoutes.set("/dashboard/maps", "Dencidad de agencias");
profilesRoutes.set("/dashboard/infocomercial/premiospotenciales", "Premios Potenciales");
profilesRoutes.set("/dashboard/presupuestos", "Presupuesto");
profilesRoutes.set("/dashboard/presupuestosupload", "Presupuesto");
profilesRoutes.set("/dashboard/user/setting", "Administracion");

/**
 *
 * @param route : string
 * @desc It checks if your profile allows you to see that route
 */
export const checkRoute = (
  route: string,
  profiles: Security.PerfilesResponse[]
): boolean => {
  const myprof = profilesRoutes.get(route);
  return profiles.some(function (item) {
    return item.nombre === myprof && item.Funcion > 0;
  });
};

/**
 * @desc exit to app
 */
export const ExitApp = () => {
  localStorage.clear();
  deleteCookie('token');
  window.location.replace("/login");
};

export const MenuVerify = (profiles: Security.PerfilesResponse[]) => {
  const menuTableroItem = document.querySelectorAll(".menu_tablero");
  const menuUtilidadesItem = document.querySelectorAll(".menu_utilidades");
  const menuContratacionesItem = document.querySelectorAll(
    ".menu_contrataciones"
  );
  const menuPresupuestoItem = document.querySelectorAll(".menu_presupuesto");
  const menuPresupuestoVerItem = document.querySelectorAll(".menu_prespuestoVer");
  const menuPresupuestoCargarItem = document.querySelectorAll(".menu_prespuestoCargar");

  const menuInfoComercialItem = document.querySelectorAll(".menu_infocomercial");
  const menuDenciadaDeAgenciasItem = document.querySelectorAll(
    ".menu_dencidaddeagencias"
  );
  const menuPremiosPotencialesItem = document.querySelectorAll(
    ".menu_premiospotenciales"
  );
  const menuConfiguracionItem = document.querySelectorAll(
    ".menu_configuracion"
  );

  const menuExportarItem = document.querySelectorAll(
    ".menu_exportar"
  );
  profiles.forEach((data) => {
    // console.log(data.nombre);
    if (data.nombre === "Tablero principal" && data.Funcion > 0) {
      menuTableroItem.forEach((tab) => {
        tab.classList.remove("hideTotal");
      });
    }
    if (data.nombre === "Utilidades" && data.Funcion > 0) {
      menuUtilidadesItem.forEach((tab) => {
        tab.classList.remove("hideTotal");
      });
    }
    if (data.nombre === "Contrataciones" && data.Funcion > 0) {
      menuContratacionesItem.forEach((tab) => {
        tab.classList.remove("hideTotal");
      });
    }

    /**
     * Info Comercial
     */
    if (data.nombre === "Premios Potenciales" && data.Funcion > 0) {
      menuPremiosPotencialesItem.forEach((tab) => {
        tab.classList.remove("hideSubmenu");
        menuInfoComercialItem.forEach((infotab) => {
          infotab.classList.remove("hideTotal");
        });
      });
    }
    if (data.nombre === "Dencidad de agencias" && data.Funcion > 0) {
      menuDenciadaDeAgenciasItem.forEach((tab) => {
        tab.classList.remove("hideSubmenu");
        menuInfoComercialItem.forEach((infotab) => {
          infotab.classList.remove("hideTotal");
        });
      });
    }

    /**
     * Presupuesto
     */
    if (data.nombre === "Presupuesto" && data.Funcion > 0) {
      menuPresupuestoVerItem.forEach((tab) => {
        tab.classList.remove("hideSubmenu");
        menuPresupuestoItem.forEach((pres) => {
          pres.classList.remove("hideTotal");
        });
      });
    }
    if (data.nombre === "Presupuesto" && data.Funcion > 1) {
      menuPresupuestoCargarItem.forEach((tab) => {
        tab.classList.remove("hideSubmenu");
        menuPresupuestoItem.forEach((pres) => {
          pres.classList.remove("hideTotal");
        });
      });
    }


    if (data.nombre === "Administracion" && data.Funcion === 3) {
      // console.log(data)
      menuConfiguracionItem.forEach((tab) => {
        tab.classList.remove("hideSubmenu");
        menuConfiguracionItem.forEach((infotab) => {
          infotab.classList.remove("hideSubmenu");
        });
      });
    }

    if (data.nombre === "Utilidades" && (data.Funcion === 3 || data.Funcion === 2)) {
      // console.log(data);
      menuExportarItem.forEach((tab) => {
        tab.classList.remove("hideSubmenu");
        menuExportarItem.forEach((infotab) => {
          infotab.classList.remove("hideSubmenu");
        });
      });
    }
  });
};

/* MENU */

/*const { left } = require("@popperjs/core");*/

/* MENU | Abrir y cerrar menu */
export function menuOnOff() {
  const menu = document.getElementById("navMenu");
  let isXL;
  if (menu !== null) {
    const menuBotones = menu.querySelectorAll(".bt");
    if (document.querySelectorAll(".XL").length > 0) {
      isXL = document.querySelectorAll(".XL");
      // isXS = false;
    } else {
      // isXS = document.querySelectorAll(".XS");
      isXL = false;
    }
    if (isXL !== false) {
      const subMenuActivoTemp = menu.querySelectorAll(".subMenuOn");
      let subMenuActivo: HTMLElement | null,
        btSubMenuActivo: NodeListOf<Element> | null;
      subMenuActivo = null;
      btSubMenuActivo = null;
      if (subMenuActivoTemp.length > 0) {
        subMenuActivo = document.getElementById(
          "subMenu" + subMenuActivoTemp[0].id.slice(7)
        );
        if (subMenuActivo !== null) {
          btSubMenuActivo = subMenuActivo.querySelectorAll(".btSubMenu");
        }
      }
      if (subMenuActivo !== null) {
        ocultarSubMenu(subMenuActivo, btSubMenuActivo);
        girarArrows(subMenuActivoTemp[0].id.slice(7));
      }
      for (let i = 0; i < menuBotones.length; i++) {
        menuBotones[i].classList.add("hideOpacidad");
      }
      setTimeout(function () {
        for (let i = 0; i < menuBotones.length; i++) {
          menuBotones[i].classList.add("hideTotal");
        }
      }, 301);
      setTimeout(function () {
        if (menu !== null) {
          menu.classList.remove("XL");
          menu.classList.add("XS");
        }
      }, 302);
    } else {
      menu.classList.remove("XS");
      menu.classList.add("XL");
      for (let i = 0; i < menuBotones.length; i++) {
        menuBotones[i].classList.remove("hideTotal");
      }
      setTimeout(function () {
        for (let i = 0; i < menuBotones.length; i++) {
          menuBotones[i].classList.remove("hideOpacidad");
        }
      }, 300);
    }
  }
}

/* MENU | SubMenu */
/* MENU | SubMenu | 
Llamar al Submenu y ocultar cualquier otro abierto */

export function subMenuOnOff(id: string) {
  const subMenus = document.querySelectorAll(".subMenu");
  const subMenuActivo = document.getElementById("subMenu" + id);
  const subMenusInactivos = menusInactivos(subMenus, subMenuActivo);
  if (subMenuActivo !== null) {
    const btSubMenuActivo = subMenuActivo.querySelectorAll(".btSubMenu");
    if (subMenuActivo !== null) {
      if (subMenuActivo.classList.contains("subMenuOn") !== true) {
        //Si el submenu NO esta desplegado
        mostrarSubMenu(subMenuActivo, btSubMenuActivo);
        girarArrows(id);
        const idInactivasTemp = [];
        const idInactivas = [];
        for (let i = 0; i < subMenusInactivos.length; i++) {
          ocultarSubMenu(subMenusInactivos[i], subMenusInactivos[i].children);
          idInactivasTemp.push(subMenusInactivos[i].id);
          idInactivas.push(idInactivasTemp[i].slice(7));
          if (idInactivas[i] !== null) {
            const arrow = document
              .getElementById("arrow" + idInactivas[i])
              ?.classList.contains("iconArrowDown");
            if (arrow === true) {
              girarArrows(idInactivas[i]);
            }
          }
        }
      } else {
        girarArrows(id);
        ocultarSubMenu(subMenuActivo, btSubMenuActivo);
      }
    }
  }
}

/* MENU | SubMenu | 
Determina cuales menus deben cerrarse al abrir el activo */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function menusInactivos(subMS: any, subMA: any) {
  const subMTemp = [];
  for (let i = 0; i < subMS.length; i++) {
    if (subMS[i].id !== subMA.id) {
      subMTemp.push(subMS[i]);
    }
  }
  return subMTemp;
}

/* MENU | SubMenu | 
Muestra Submenu */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mostrarSubMenu(subM: any, btSub: any) {
  for (let i = 0; i < btSub.length; i++) {
    btSub[i].classList.remove("hideTotal");
  }
  subM.classList.remove("subMenuOff");
  setTimeout(function () {
    for (let i = 0; i < btSub.length; i++) {
      btSub[i].classList.remove("hideOpacidad");
    }
  }, 299);
  subM.classList.add("subMenuOn");
}

/* MENU | SubMenu | 
Oculta SubMenu */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ocultarSubMenu(subM: any, btSub: any) {
  for (let i = 0; i < btSub.length; i++) {
    btSub[i].classList.add("hideOpacidad");
  }
  setTimeout(function () {
    subM.classList.remove("subMenuOn");
    subM.classList.add("subMenuOff");
  }, 299);
  setTimeout(function () {
    for (let i = 0; i < btSub.length; i++) {
      btSub[i].classList.add("hideTotal");
    }
  }, 600);
}

/* MENU | SubMenu | 
Gira el ícono de flecha de los botones */

function girarArrows(id: string) {
  const arrowActiva = document.getElementById("arrow" + id);
  if (arrowActiva?.classList.contains("iconArrowLeft") === true) {
    arrowActiva.classList.remove("iconArrowLeft");
    arrowActiva.classList.add("iconArrowDown");
  } else {
    arrowActiva?.classList.remove("iconArrowDown");
    arrowActiva?.classList.add("iconArrowLeft");
  }
}
