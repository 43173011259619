import packageInfo from '../../package.json';

export const CONFIG = {
    urlBase: "https://tablero.iafas.gov.ar",
    version: packageInfo.version,
    OU: "TableroComandos",
    loginURL: "https://servlogin.iafas.gov.ar/",
    secret: "a2e5073e8bc025efca145c87",
    tableroWS: 'https://tableroapi.iafas.gov.ar',
    //tableroWS: 'http://172.16.24.8:5300',
    gitservice: 'https://gitservice.iafas.gov.ar/tag/88',
    urlVideoSorteo: 'https://www.youtube.com/embed/live_stream?fs=1&rel=0&hd=1&wmode=transparent&enablejsapi=1&html5=1&channel=UCt4NgEmfSs7x_c8ucw7CcXw&autoplay=1&mute=1&mute=1&autohide=1'
  };
  